.snipcart {
    @apply font-haas;
}

.snipcart__font--secondary,
.snipcart__font--subtitle,
.snipcart__font--black,
.snipcart__font--subtitle-small,
.snipcart__font--std,
.snipcart__font--slim,
.snipcart__font--tiny,
.snipcart__font--regular,
.snipcart-button-link,
.snipcart-form__label,
.snipcart__font--large,
.snipcart-base-button__label,
.snipcart-order__invoice-number,
.snipcart-cart-header__title {
    @apply font-haas text-[18px] leading-[24px] font-normal;

}

.snipcart-modal {
    border-left: rgba(229, 231, 235) solid 1px;
    @apply shadow-xl;
}

.snipcart-modal__container {
    @apply z-50;
}


/* .snipcart-item-quantity,
.snipcart-cart-summary-expanded-item__quantity {
    @apply hidden;
} */

.snipcart-item-quantity__label,
.snipcart-item-quantity__quantity,
snipcart-item-line__info {
    @apply hidden;
}

.snipcart-button-icon {
    @apply font-haas text-[18px] leading-[24px] font-normal p-0
}



.snipcart-item-quantity__quantity-price-container {
    @apply text-right justify-end;
}

.snipcart-item-line {
    border-bottom: rgba(229, 231, 235) solid 1px;

}

.snipcart-modal__close-icon,
.snipcart-modal__close-title,
.snipcart__icon--medium snipcart__icon--angled::after {
    @apply select-none font-haas text-[18px] leading-[24px] font-normal;
}

.snipcart-cart-header__count {
    @apply rounded-full border border-black;
}

.snipcart__font--bold {
    font-weight: inherit;
    line-height: inherit;
}

.snipcart-cart-header,
.snipcart-layout__content {
    @apply w-full max-w-full px-6 select-none bg-white;
}


.snipcart-cart__content,
.snipcart-cart-header {
    @apply py-5 px-6;
}

.snipcart-cart__secondary-header {
    @apply px-2 py-4 my-5 items-start min-h-0;
}

.snipcart-cart__secondary-header button::before {
    content: "close";
    @apply select-none font-haas text-[18px] leading-[24px] font-normal;
}

.snipcart-cart__secondary-header button svg {
    @apply hidden;
}

.snipcart-summary-fees__notice {
    @apply pb-4;
}

.snipcart-base-button {
    @apply rounded-none;
}


.snipcart-cart__footer {
    @apply px-4;
}

.snipcart-cart-summary-side,
.snipcart-cart__content,
.snipcart-item-list,
.snipcart-layout__content--side,
.snipcart-item-line__container {
    @apply px-0 mx-0;
}


.snipcart-cart-header__icon,
.snipcart-featured-payment-methods__title-icon,
.snipcart__icon--blue-dark,
.snipcart__icon--left {
    @apply hidden border-black border;
}

.snipcart-button-icon svg {
    @apply hidden;
}

.snipcart__icon svg {
    @apply hidden;
}

.snipcart__icon::after {
    content: "\2715";
}

.snipcart-button-icon::before {
    content: "\2715";
}

.snipcart-cart-header__count {
    @apply border-black border;
}

.ais-SearchBox,
.ais-SearchBox-input {
    @apply w-full;
}

.ais-SearchBox-submit,
.ais-SearchBox-resetIcon {
    @apply hidden;
}



.snipcart-item-line__media--small {
    @apply h-14 w-32 object-contain;
}

.snipcart-item-line--cart-edit .snipcart-item-line__image {
    @apply max-w-sm;
}


/* colors */
#snipcart {

    /* ----------------- 
        Colors
    ----------------- */
    --color-default: black;
    --color-alt: black;
    --color-icon: black;
    --color-success: black;
    --color-error: black;

    --color-link: black;
    --color-link-hover: black;
    --color-link-active: var(--color-link);
    --color-link-focus: var(--color-link);

    --color-input: var(--color-default);
    --color-input-hover: var(--color-input);
    --color-input-focus: var(--color-input);
    --color-input-checked: var(--color-input);
    --color-input-disabled: var(--color-alt);
    --color-input-error: var(--color-error);
    --color-inputLabel: var(--color-default);
    --color-inputLabel-hover: var(--color-inputLabel);
    --color-inputLabel-focus: var(--color-inputLabel);
    --color-inputIcon: var(--color-alt);
    --color-inputIcon-hover: var(--color-default);
    --color-inputIcon-focus: var(--color-inputIcon);
    --color-inputIcon-checked: var(--color-default);
    --color-inputIcon-error: var(--color-error);
    --color-inputPlaceholder: var(--color-alt);

    --color-buttonPrimary: white;
    --color-buttonPrimary-hover: white;
    --color-buttonPrimary-active: var(--color-buttonPrimary);
    --color-buttonPrimary-focus: var(--color-buttonPrimary);
    --color-buttonPrimary-disabled: var(--color-alt);
    --color-buttonPrimary-success: var(--color-buttonPrimary);
    --color-buttonPrimary-error: var(--color-buttonPrimary);

    --color-buttonSecondary: var(--color-icon);
    --color-buttonSecondary-hover: black;
    --color-buttonSecondary-active: var(--color-buttonSecondary);
    --color-buttonSecondary-focus: var(--color-buttonSecondary);
    --color-buttonSecondary-disabled: hsl(210, 10%, 25%);
    --color-buttonSecondary-success: var(--color-success);
    --color-buttonSecondary-error: var(--color-error);

    --color-buttonDanger: var(--color-error);
    --color-buttonDanger-hover: black;
    --color-buttonDanger-active: var(--color-buttonDanger);
    --color-buttonDanger-focus: var(--color-buttonDanger);
    --color-buttonDanger-disabled: black;
    --color-buttonDanger-success: var(--color-default);
    --color-buttonDanger-error: var(--color-default);

    --color-badge: var(--color-link);
    --color-badge-active: var(--color-link);
    --color-badge-disabled: var(--color-alt);

    /* ----------------- 
        Border colors
    ----------------- */
    --borderColor-default: transparent;
    --borderColor-error: transparent;

    --borderColor-link: transparent;
    --borderColor-link-hover: transparent;
    --borderColor-link-active: transparent;
    --borderColor-link-focus: transparent;

    --borderColor-input: transparent;
    --borderColor-input-hover: transparent;
    --borderColor-input-focus: transparent;
    --borderColor-input-checked: transparent;
    --borderColor-input-disabled: transparent;
    --borderColor-input-error: transparent;

    --borderColor-buttonPrimary: transparent;
    --borderColor-buttonPrimary-hover: transparent;
    --borderColor-buttonPrimary-focus: transparent;
    --borderColor-buttonPrimary-disabled: transparent;
    --borderColor-buttonPrimary-success: transparent;
    --borderColor-buttonPrimary-error: transparent;

    --borderColor-buttonSecondary: transparent;
    --borderColor-buttonSecondary-hover: transparent;
    --borderColor-buttonSecondary-focus: transparent;
    --borderColor-buttonSecondary-disabled: transparent;
    --borderColor-buttonSecondary-success: transparent;
    --borderColor-buttonSecondary-error: transparent;

    --borderColor-badge: transparent;
    --borderColor-badge-active: transparent;
    --borderColor-badge-disabled: transparent;

    /* ----------------- 
        Background colors
    ----------------- */
    --bgColor-default: white;
    --bgColor-alt: white;
    --bgColor-success: white;
    --bgColor-error: white;
    --bgColor-info: white;

    --bgColor-modal: white;
    --bgColor-modalVeil: white;

    --bgColor-link: white;
    --bgColor-link-hover: white;
    --bgColor-link-active: white;
    --bgColor-link-focus: white;

    --bgColor-input: lightgray;
    --bgColor-input-hover: lightgray;
    --bgColor-input-focus: lightgray;
    --bgColor-input-checked: lightgray;
    --bgColor-input-disabled: lightgray;
    --bgColor-input-error: lightgray;
    --bgColor-input-autofill: lightgray;

    --bgColor-buttonPrimary: black;
    --bgColor-buttonPrimary-hover: black;
    --bgColor-buttonPrimary-active: black;
    --bgColor-buttonPrimary-focus: black;
    --bgColor-buttonPrimary-disabled: black;
    --bgColor-buttonPrimary-success: black;
    --bgColor-buttonPrimary-error: black;

    --bgColor-buttonSecondary: white;
    --bgColor-buttonSecondary-hover: white;
    --bgColor-buttonSecondary-active: white;
    --bgColor-buttonSecondary-focus: white;
    --bgColor-buttonSecondary-disabled: white;
    --bgColor-buttonSecondary-success: white;
    --bgColor-buttonSecondary-error: white;

    --bgColor-buttonDanger: white;
    --bgColor-buttonDanger-hover: white;
    --bgColor-buttonDanger-active: white;
    --bgColor-buttonDanger-focus: white;
    --bgColor-buttonDanger-disabled: white;
    --bgColor-buttonDanger-success: white;
    --bgColor-buttonDanger-error: white;

    --bgColor-badge: white;
    --bgColor-badge-active: lightgray;
    --bgColor-badge-disabled: lightgray;

    /* ----------------- 
        Shadows
    ----------------- */

    --shadow-default: none;
    --shadow-tooltip: none;

    --shadow-link-focus: none;

    --shadow-input-hover: none;
    --shadow-input-focus: none;
    --shadow-input-checked: none;

    --shadow-buttonPrimary-hover: none;
    --shadow-buttonPrimary-active: none;
    --shadow-buttonPrimary-focus: none;

    --shadow-buttonSecondary-hover: none;
    --shadow-buttonSecondary-active: none;
    --shadow-buttonSecondary-focus: none;

    --shadow-buttonDanger-hover: none;
    --shadow-buttonDanger-active: none;
    --shadow-buttonDanger-focus: none;
}