@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url("https://cdn.snipcart.com/themes/v3.2.1/default/snipcart.css");

@import url("https://p.typekit.net/p.css?s=1&k=lrq1jfn&ht=tk&f=39516.39517.39518.39523&a=84982375&app=typekit&e=css");



@import url("iubendaCustom.css");
@import url("swiperCustom.css");
@import url("snipcartCustom.css");

@font-face {
    font-family: "neue-haas-unica";
    src: url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/e07c44/00000000000000007735bb1b/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "neue-haas-unica";
    src: url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/d7f1e9/00000000000000007735bb2a/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

*:focus {
    outline: none;
}

b,
strong {
    font-style: normal;
    font-weight: normal;
}

html {
    scroll-behavior: smooth;
    -webkit-appearance: none;
}

body {
    @apply font-haas antialiased;
    font-family: "neue-haas-unica",
        sans-serif;

}

.navigation p:hover {
    @apply text-[#AAAAAA] transition duration-300;
}

.footer p a,
span:hover {
    @apply text-[#AAAAAA] transition duration-300;

}




a:hover {
    @apply text-[#AAAAAA] transition duration-300;
}

.sectionm {
    @apply px-0;
}

section {
    @apply px-4;
}

.underline {
    text-decoration: none;
}

.underline a {
    border-bottom: 1px solid #CECECE;
}

.draw {
    stroke-dasharray: 2338.5;
    stroke-dashoffset: 2338.5;
    animation: draw 3s linear infinite;
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}



@screen lg {
    section {
        @apply px-6;
    }

    .sectionm {
        @apply px-6;
    }


}



.iiz img {
    @apply w-full;
}